import './App.css';
import React from 'react';
import API from '../../components/API/API';
import AppRoutes from '../../components/AppRoutes/AppRoutes';


function App() {
    return (
        <React.StrictMode>            
                <API>
                    <AppRoutes />
                </API>           
        </React.StrictMode>
    );
}

export default App;
