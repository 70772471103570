import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        paddingTop: '80px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '50px',
        },
    },
    pageTitle: {
        color: '#1e375f',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '3rem',
        fontWeight: '600',
        marginLeft: '5px',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem',
        },
    },
    pageSubTitle: {
        color: '#5a5a5a',
        fontSize: '1.2rem',
        fontFamily: "'Titillium Web', sans-serif",
        marginBottom: '20px',
        marginLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    paperWrapper: {
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f1f1f1',
    },
    boxTitle: {
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        fontWeight: '600',
        fontFamily: "'Titillium Web', sans-serif",
        color: '#1e375f',
    },
    boxCounter: {
        fontSize: '6rem',
        color: '#577080',
    },
    boxedLink: {
        backgroundColor: '#c0e6ff',
        color: '#2c3d63',
        padding: '20px',
        width: '100%',
        display: 'block',
        borderRadius: '4px',
        fontSize: '1.2rem',
        fontFamily: "'Titillium Web', sans-serif",
        textTransform: 'uppercase',
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#87cfff',
        }
    },
    tableTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        color: '#2c3d63',
        fontSize: '1.5rem',
        marginBottom: '20px',
    },
    siteImg: {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '50px',
        },
    }
}));

export { useStyles };