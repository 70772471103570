import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: '50px',
    },
    formTitle: {
        textAlign: 'center',
        marginBottom: '50px',
        fontSize: '3.5rem',
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
    },
}));

export { useStyles };