import React from 'react';
import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
import useLocalStorage from '../../utils/useLocalStorage';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DashboardIcon from '@mui/icons-material/Dashboard';

const ApiContext = createContext({});

export default function API({ children }) {

    //const baseURL = process.env.REACT_APP_API_URL;
    const baseURL = 'https://segnalazioni.roseto.actainfo.it/api/';

    const [accessToken, setAccessToken] = useLocalStorage('access_token');
    const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token');
    const [user, setUser] = useLocalStorage('user');

    // Admin Links

    const adminLinks = [
        {
            title: 'Dashboard',
            link: '/admin',
            icon: <DashboardIcon />,
        },
        {
            title: 'Segnalazioni',
            link: '/admin/segnalazioni/stato/attive',
            icon: <ReportProblemIcon />,
        },
        {
            title: 'Risolte',
            link: '/admin/segnalazioni/stato/risolte',
            icon: <FolderOpenIcon />,
        },
    ];

    // Privacy Links

    const privacyLinks = [
        {
            title: 'Informativa Privacy',
            link: 'https://www.comune.roseto.te.it/informativa-privacy',
        }
    ];

    // Axios Instance
    const createAxiosInstance = () => {

        const instance = axios.create({
            baseURL: baseURL,
            timeout: 30000,
            headers: {
                Authorization: accessToken
                    ? 'JWT ' + accessToken
                    : null,
                'Content-Type': 'application/json',
                accept: 'application/json',
            }, 
        });

        const noInterceptAxios = axios.create({
            baseURL: baseURL,
            timeout: 30000,
            headers: {
                Authorization: accessToken
                    ? 'JWT ' + accessToken
                    : null,
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        });

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async function (error) {
                const originalRequest = error.config;

                if (typeof error.response === 'undefined') {
                    alert(
                        'A server/network error occurred. ' +
                            'Looks like CORS might be the problem. ' +
                            'Sorry about this - we will get it fixed shortly.'
                    );
                    return Promise.reject(error);
                }

                if (
                    error.response.status === 401 &&
                    originalRequest.url === baseURL + 'token/refresh/'
                ) {
                    window.location.href = '/login/';
                    return Promise.reject(error);
                }

                if (
                    error.response.data.code === 'token_not_valid' &&
                    error.response.status === 401 &&
                    error.response.statusText === 'Unauthorized' && 
                    !originalRequest._retry
                ) {
                    if (refreshToken) {
                        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                        // exp date in token is expressed in seconds, while now() returns milliseconds:
                        const now = Math.ceil(Date.now() / 1000);
                        //console.log(tokenParts.exp);
                        originalRequest._retry = true;

                        if (tokenParts.exp > now) {
                            noInterceptAxios
                                .post('/token/refresh/', { refresh: refreshToken })
                                .then((response) => {
                                    setAccessToken(response.data.access);
                                    setRefreshToken(response.data.refresh);

                                    //noInterceptAxios.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                                    //originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

                                    //return noInterceptAxios(originalRequest);
                                    window.location.reload();
                                });
                        } else {
                            //console.log('Refresh token is expired', tokenParts.exp, now);
                            localStorage.removeItem('access_token');
                            localStorage.removeItem('refresh_token');
                            localStorage.removeItem('user');
                            window.location.href = '/login/';
                        }
                    } else {
                        //console.log('Refresh token not available.');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('user');
                        window.location.href = '/login/';
                    }
                }

                // specific error handling done elsewhere
                return Promise.reject(error);
            }
        );

        return instance;
    }

    const [axiosInstance, setAxiosInstance] = useState(createAxiosInstance);

    useEffect(() => {
        setAxiosInstance(createAxiosInstance);
    }, [accessToken, refreshToken]);

    // Login
    const login = async(formData) => {

        const { data } = await axiosInstance.post('token/', {...formData });

        if(data) {
            setAccessToken(data.access);
            setRefreshToken(data.refresh);
            setUser(formData.username);
            axiosInstance.defaults.headers['Authorization'] = 'JWT ' + accessToken;
        }

        return data;
    }

    // Logout
    const logout = async() => {

        await axiosInstance.post('user/logout/blacklist/', { refresh_token: refreshToken });
        
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        axiosInstance.defaults.headers['Authorization'] = null;         

        window.location.href = '/login/';
    }

    // Get Company Info
    const [company, setCompany] = useState({});

    useEffect(() => {     
        axiosInstance.get('company/info/').then((res) => {
            const companyInfo = res.data[0];
            setCompany({ ...companyInfo });
        });               
    }, []);

    // Get Categories
    const listCategories = async() => {
        const { data } = await axiosInstance.get('categories');
        return data;
    }

    // Appointments List 
    const listReports = async(state) => {
        if(state === 'attive'){
            state = 'active';
        } else if(state === 'risolte'){
            state = 'archived';
        } 
        const { data } = await axiosInstance.get('reports/state/' +  state );
        return data;
    }

    // Get Report Details
    const reportDetails = async(report) => {
        const { data } = await axiosInstance.get('reports/' + report );
        return data;
    }

    // Get Latest Reports
    const latestReports = async() => {
        const { data } = await axiosInstance.get('reports/latest/');
        return data;
    }

    // Submit Report Form
    const submitReport = async(formData) => {
        const { data } = await axiosInstance.post('reports/', formData);
        return data;
    }

    // Edit Report
    const editReport = async(report, formData) => {
        const { data } = await axiosInstance.patch('reports/' +  report + '/', {...formData} );
        return data;
    }

    // Delete Report
    const deleteReport = async(report) => {
        const { data } = await axiosInstance.delete('reports/' +  report + '/');
        return data;
    }

    const api = {  
        login,
        logout,      
        company,
        listCategories,
        listReports,
        submitReport,
        editReport,
        deleteReport,
        reportDetails,
        latestReports,
        isAuth: !!user,
        user,
        adminLinks,
        privacyLinks,
    }

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export { ApiContext };