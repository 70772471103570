import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        paddingTop: '80px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    pageTitle: {
        color: '#1e375f',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginLeft: '5px',
    },
    pageSubTitle: {
        color: '#5a5a5a',
        fontFamily: "'Titillium Web', sans-serif",
        marginBottom: '20px',
        marginLeft: '5px',
    },
}));

export { useStyles };