import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Search, GpsFixed } from "@material-ui/icons";

import ReportMap from "./ReportMap";

import { useStyles } from './ReportFormStyles.js';
import './Map.css';

//const apiKey = process.env.REACT_APP_GOOGLE_KEY;
const apiKey = 'AIzaSyAQ4jAmN95GznhDL9ih25vcWHVk4VoTwzo';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';


// load google map api js

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const extractAddress = (place) => {

  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    route: "",
    plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        const route = this.route ? this.route + ", " : "";
        return route + city + zip + state + this.country;
    }
  }

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  if(typeof place.geometry.location.lat === 'function') {
    address.lat = place.geometry.location.lat();
  } else {
    address.lat = place.geometry.location.lat;
  }

  if(typeof place.geometry.location.lng === 'function') {
    address.lng = place.geometry.location.lng();
  } else {
    address.lng = place.geometry.location.lng;
  }

  place.address_components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("route")) {
      address.route = value;
    }

    if (types.includes("street_number")) {
      address.street = value;
    }

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }

  });

  return address;
}


function ReportForm({ company, listCategories, submitReport, privacyLinks }) {

    const classes = useStyles();   

    const searchInput = useRef(null);
    const [address, setAddress] = useState({});

    const [coords, setCoords] = useState({
        lat: 42.67975639360979,
        lng: 14.01105169279305,
    });

    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if(window.google) {
          return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(extractAddress(place));
        setCoords({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        });
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

    }


    const reverseGeocode = ({ latitude: lat, longitude: lng}) => {
        const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
        searchInput.current.value = "Getting your location...";
        fetch(url)
            .then(response => response.json())
            .then(location => {
              const place = location.results[0];
              const _address = extractAddress(place);
              setAddress(_address);
              searchInput.current.value = _address.plain();
              setCoords({
                lat: lat,
                lng: lng,
              });
            })
    }

    const findMyLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            reverseGeocode(position.coords)
          })
        }
    }

    // load map script after mounted
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [searchInput.current]); 

    const [categoryList, setCategoryList] = useState(null);

    useEffect(() => {
        (async() => {
            const categoryList = await listCategories();
            setCategoryList(categoryList);
        })();
    }, [listCategories]);  

    const initialPostData = Object.freeze({
        category: '',
        description: '',
        reporter_first_name: '',
        reporter_last_name: '',
        reporter_email: '',
        reporter_phone: '',
        accept_privacy: 'off',
    });

    const [postData, updatePostData] = useState(initialPostData);
    const [postImage1, setPostImage1] = useState({image: null});
    const [postImage2, setPostImage2] = useState({image: null});
    const [postImage3, setPostImage3] = useState({image: null});

    const handleChange = (e) => {
        if([e.target.name] == 'report_image_1') {
            setPostImage1({
                image: e.target.files[0],
            });
        } else if([e.target.name] == 'report_image_2') {
            setPostImage2({
                image: e.target.files[0],
            });
        } else if([e.target.name] == 'report_image_3') {
            setPostImage3({
                image: e.target.files[0],
            });
        } else if([e.target.name] == 'category') {
            updatePostData({
                ...postData,
                [e.target.name]: e.target.value,
            });
        } else if([e.target.name] == 'accept_privacy') {
            if(postData.accept_privacy === 'off') {
                updatePostData({
                    ...postData,
                    'accept_privacy': 'on',
                });
            } else {
                updatePostData({
                    ...postData,
                    'accept_privacy': 'off',
                });
            }  
        } else  {
            updatePostData({
                ...postData,
                //Trimming any whitespace
                [e.target.name]: e.target.value.trim(),
            });
        }
    };

    const [buttonText, setButtonText] = useState('Invia');
    const [formResponse, setFormResponse] = useState(false);
    const [formError, setFormError] = useState({
        error: false,
    });

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Check Errors
        if(postData.category === '' || Object.keys(address).length === 0 || postData.description === '' || postData.reporter_first_name === '' || postData.reporter_last_name === '' || postData.reporter_email === '' || postData.reporter_phone === '' || postData.accept_privacy === 'off') {
            setFormError({
                error: true,
            });
            return;
        }

        let formData = new FormData();
        formData.append('category', postData.category);
        formData.append('address', address.route + ', ' + address.city + ', ' + address.zip + ', ' + address.state + ', ' + address.country );
        formData.append('description', postData.description);
        formData.append('reporter_first_name', postData.reporter_first_name);
        formData.append('reporter_last_name', postData.reporter_last_name);
        formData.append('reporter_email', postData.reporter_email);
        formData.append('reporter_phone', postData.reporter_phone);
        if(postImage1.image !== null) {
            formData.append('report_image_1', postImage1.image);
        } 
        if(postImage2.image !== null) {
            formData.append('report_image_2', postImage2.image);
        } 
        if(postImage3.image !== null) {
            formData.append('report_image_3', postImage3.image);
        }
        
        setFormError(false);

        setButtonText('Invio in corso...');

        const submitForm = await submitReport(formData);
        setFormResponse(submitForm.success);
    };

    if (!categoryList || categoryList.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <>
        <Container maxWidth="xl" className={classes.formMainWrapper}>
            <Grid container className={classes.appointmentFormGrid}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} className={classes.formWrapper}>
                        <Typography variant="h5" className={classes.formTitle} mb={2}>
                            Invia una segnalazione
                        </Typography>
                        {(formResponse === 'Sent') 
                            ? <p><Alert severity="success" className={classes.formResponse}>Il messaggio è stato inviato correttamente.</Alert></p>
                            :
                            <form className={classes.form} >
                                <Grid item xs={12} mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="category-select-label">Categoria *</InputLabel>
                                        <Select
                                            required
                                            labelId="category-select-label"
                                            id="category-select"
                                            label="Categoria"
                                            name="category"
                                            onChange={handleChange}
                                            value={postData.category}
                                            className={classes.inputField}
                                            error={formError.error}
                                        >
                                        {categoryList.map((category) => {
                                            return(
                                                <MenuItem 
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </MenuItem>
                                            );
                                        })}
                                        </Select>
                                    </FormControl>
                                    {formError.error && <FormHelperText className={classes.error}>Selezionare Categoria</FormHelperText>}   
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="description"
                                        name="description"
                                        label="Testo della segnalazione"
                                        multiline
                                        rows={3}
                                        onChange={handleChange}
                                        className={classes.inputField}
                                        helperText={formError.error && 'Inserisci un messaggio'}   
                                        error={formError.error}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} mb={5}>
                                    <div>
                                        <div className="search">
                                            <span><Search /></span>
                                            <input 
                                                name="address" 
                                                ref={searchInput} 
                                                type="text" 
                                                placeholder="Cerca Indirizzo...." 
                                                //onChange={handleChange} 
                                            />
                                            <button type="button" onClick={findMyLocation}><GpsFixed /></button>
                                        </div>
                                        <div className={classes.error}>{formError.error && 'Inserisci indirizzo'}</div>
                                        <div className={classes.helperText}>Digitare e scegliere dall'elenco un indirizzo oppure cliccare sul tasto <GpsFixed className={classes.helperText} /> per la localizzazione automatica</div>
                                    </div>
                                </Grid>

                                <ReportMap coords={coords} />

                                <hr />
                                <Grid container spacing={2} mb={4} mt={1}>
                                    <Grid item xs={12} mb={1}>
                                        <Typography className={classes.formText}>
                                            Inserisci fino a 3 foto (dimensione massima 30MB totali)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            variant="outlined" 
                                            fullWidth 
                                            id="report_image_1" 
                                            label="Immagine"
                                            name="report_image_1"
                                            onChange={handleChange}
                                            accept="image/*"
                                            type="file"
                                            InputLabelProps={{ shrink: true }} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            variant="outlined" 
                                            fullWidth 
                                            id="report_image_2" 
                                            label="Immagine"
                                            name="report_image_2"
                                            onChange={handleChange}
                                            accept="image/*"
                                            type="file"
                                            InputLabelProps={{ shrink: true }} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            variant="outlined" 
                                            fullWidth 
                                            id="report_image_3" 
                                            label="Immagine"
                                            name="report_image_3"
                                            onChange={handleChange}
                                            accept="image/*"
                                            type="file"
                                            InputLabelProps={{ shrink: true }} 
                                        />
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container spacing={2} mb={5} mt={2}>
                                    <Grid item xs={12} mb={1}>
                                        <Typography className={classes.formText}>
                                            Inserisci i tuoi dati
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="reporter_first_name"
                                            label="Nome"
                                            name="reporter_first_name"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.error && 'Inerisci nome'}   
                                            error={formError.error}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="reporter_last_name"
                                            label="Cognome"
                                            name="reporter_last_name"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.error && 'Inerisci cognome'}   
                                            error={formError.error}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="reporter_email"
                                            label="Indirizzo Email"
                                            name="reporter_email"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.error && 'Indirizzo email non valido'}   
                                            error={formError.error}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="reporter_phone"
                                            label="Telefono"
                                            name="reporter_phone"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.error && 'Inserisci telefono'}   
                                            error={formError.error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox value={postData.accept_privacy} name="accept_privacy" color="primary" onChange={handleChange} />}
                                            label="Esprimo il consenso al trattamento dei miei dati personali secondo le modalità e finalità esplicitate nell'informativa. *"
                                            label={
                                               <div>
                                                  <span>Esprimo il consenso al trattamento dei miei dati personali secondo le modalità e finalità esplicitate nell'</span>
                                                  <Link href={privacyLinks[0].link} target="_blank">informativa</Link>
                                                  <span> *</span>
                                               </div>
                                            }
                                            className={classes.checkboxLabel}
                                        />
                                        {formError.error && <FormHelperText className={classes.error}>Selezionare la casella</FormHelperText>}   
                                    </Grid>
                                    <Grid item xs={12} mt={5}>
                                        <Typography className={classes.helperText}>
                                            * campi obbligatori (i tuoi dati sono necessari per ricontattarti in caso di necessità).
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                    disabled={buttonText === 'Invio in corso...' && true}
                                >
                                    {buttonText}
                                </Button>
                            </form>
                        }                       
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );
}

export default ReportForm;
