import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appBarWrapper: {
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    appBar: {
        backgroundColor: '#0071b8 !important',
    },
    headerWrapper: {
        [theme.breakpoints.down('xs')]: {
           padding: '0',
        },
    },
    link: {
        color: '#ffffff !important',
        fontSize: '1.1rem',
        fontFamily: '"Titillium Web", sans-serif',
        fontWeight: '700',
        margin: '10px 20px !important',
        [theme.breakpoints.down('lg')]: {
            margin: '5px 10px !important',
        }
    },
    socialAppBar: {
        backgroundColor: '#0071b8',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    socialLink: {
        margin: '5px 10px !important',
        color: '#ffffff !important',
    },
    contactLink: {
        fontSize: '.875rem !important',
        color: '#ffffff !important',
        margin: '5px 10px !important',
    },
    mobileSiteWrapper: {
        display: 'none',
        fontFamily: '"Titillium Web", sans-serif',
        [theme.breakpoints.down('md')]: {
           display: 'block',
        },
    },
    mobileSiteImg: {
        verticalAlign: 'middle',
        //marginLeft: '20px',
    },
    mobileSiteTitle: {
        fontFamily: '"Titillium Web", sans-serif',
        fontWeight: '700',
        marginLeft: '10px',
        color: '#fff',
    },
    loginButton: {
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
        '&:hover': {
            backgroundColor: '#fff',
            color: '#475360',
            border: '1px solid rgb(255 255 255)',
        },
    },
    dashboardButton: {
        color: '#475360',
        marginRight: '5px',
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    loggedMessage: {
        fontFamily: '"Titillium Web", sans-serif',
        marginRight: '5px',
        fontSize: '.875rem',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    siteNameTop: {
        color: '#fff',
        fontFamily: '"Titillium Web", sans-serif',
        verticalAlign: 'text-bottom',
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
           fontSize: '.875rem',
        },
    },
    companySlogan: {
        fontFamily: '"Titillium Web", sans-serif',
        marginLeft: '85px',
        marginTop: '-35px',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
           fontSize: '.75rem',
        },
    },
}));

export { useStyles };
