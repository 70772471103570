import React from 'react';
import { Outlet  } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { useStyles } from './DashboardStyles.js';

const drawerWidth = 240;

function Dashboard(props) {

  const classes = useStyles();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
        <Typography className={classes.sidebarTitle}>
          Servizio Segnalazioni
        </Typography>
      <Divider />
      
      <List sx={{ marginTop: '20px' }}>
        {props.adminLinks.map((admin) => (
          <ListItem button key={admin.title} className={classes.adminLinkList}>
            <Link 
                href={admin.link}
                underline="none"
                className={classes.adminLink}
                key={`admin_${admin.title}`}
              >
                <span className={classes.adminLinkIcon}>{admin.icon}</span>
                <span className={classes.adminLinkTitle}>{admin.title}</span>
              </Link>
          </ListItem>
        ))}
      </List>
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        className={classes.dashboardAppBar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            className={classes.dashboardHamburger}
          >
            <MenuIcon className={classes.dashboardHamburger} />
          </IconButton>
          <Typography 
              variant="h6" 
              color="inherit" 
              noWrap
              sx={{ flexGrow: 1 }}
          >
            <Link 
              href="/"
              sx={{ textDecoration: 'none' }}
            >
              <img src={props.company.logo} width="30" className={classes.siteImg} alt={props.company.name} />
              <span className={classes.siteTitle}>{props.company.name}</span>
            </Link>
            <Link href="/" className={classes.homeLink}><HomeIcon sx={{ verticalAlign: 'sub' }} /> Home</Link>
          </Typography>
          <Typography className={classes.loggedMessage}><PersonIcon sx={{ verticalAlign: 'sub' }} /> {props.user}</Typography>
          <Button href='/admin' color="inherit" variant="outlined" className={classes.dashboardButton}>Area Protetta</Button>
          <Button onClick={props.logout} color="inherit" variant="outlined" className={classes.loginButton}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#577080' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#577080' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default Dashboard;
