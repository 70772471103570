import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes as Switch } from 'react-router-dom';
import { useContext } from 'react';
import { ApiContext } from '../API/API';
import LoginForm from '../LoginForm/LoginForm';
import HomePage from '../HomePage/HomePage';
import ReportsList from '../Admin/ReportsList/ReportsList';
import ReportDetails from '../Admin/ReportDetails/ReportDetails';
import EditReport from '../Admin/EditReport/EditReport';
import DeleteReport from '../Admin/DeleteReport/DeleteReport';
import AdminMain from '../Admin/AdminMain/AdminMain';
import Dashboard from '../../views/Admin/Dashboard/Dashboard';
import MainLayout from '../../views/MainLayout/MainLayout';
import ScrollToTop from '../../utils/ScrollToTop';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

function AppRoutes() {

    const api = useContext(ApiContext); 

    return (
        <>
        <Router>
            <ScrollToTop />
            <CssBaseline />  
            <Box sx={{ flexGrow: 1, height: '100%' }}>        
                <Switch>
                    <Route path="/" element={<MainLayout company={api.company} isAuth={api.isAuth} user={api.user} logout={api.logout} privacyLinks={api.privacyLinks} />}>
                        <Route exact path="/" element={<HomePage company={api.company} listCategories={api.listCategories} submitReport={api.submitReport} privacyLinks={api.privacyLinks} />} />
                        <Route exact path="/login" element={<LoginForm login={api.login} company={api.company} />} />
                    </Route>                  
                    <Route exact path="/admin" element={api.isAuth ? <Dashboard company={api.company} logout={api.logout} adminLinks={api.adminLinks} /> : <Navigate to="/login" />} >  
                        <Route exact path="/admin" element={<AdminMain company={api.company} listReports={api.listReports} latestReports={api.latestReports} />} />
                        <Route exact path="/admin/segnalazioni/" element={<Navigate to="/admin/segnalazioni/stato/attive" />} />
                        <Route exact path="/admin/segnalazioni/stato/" element={<Navigate to="/admin/segnalazioni/stato/attive" />} />
                        <Route exact path="/admin/segnalazioni/stato/:state" element={<ReportsList listReports={api.listReports} />} />
                        <Route exact path="/admin/segnalazioni/modifica/" element={<Navigate to="/admin/segnalazioni/stato/attive" />} />
                        <Route exact path="/admin/segnalazioni/modifica/:reportID" element={<EditReport reportDetails={api.reportDetails} editReport={api.editReport} />} />
                        <Route exact path="/admin/segnalazioni/elimina/" element={<Navigate to="/admin/segnalazioni/stato/attive" />} />
                        <Route exact path="/admin/segnalazioni/elimina/:reportID" element={<DeleteReport reportDetails={api.reportDetails} deleteReport={api.deleteReport} />} />
                        <Route exact path="/admin/segnalazioni/:reportID" element={<ReportDetails reportDetails={api.reportDetails} />} />
                    </Route>
                </Switch>                
            </Box> 
        </Router>          
        </>
    );

}

export default AppRoutes;