import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { NavLink } from 'react-router-dom';
import ActaLogo from '../../media/images/logo_actainfo.png'

import { useStyles } from './FooterStyles.js'

function Footer({ company, privacyLinks }) {

    const classes = useStyles();

    return(  
        <React.Fragment>
            <Box className={classes.footerWrapper}>
                <Container maxWidth="xl" component="footer" className={classes.footer}>
                    <Grid container spacing={4} justifyContent="space-evenly">
                        <Grid item key="companyFooter1" xs={12} sm={12} md={6}>
                            <Grid container>
                                <Grid item key="companyLogo" md={2}> 
                                    <img src={company.logo_alt} width="80" alt={company.name} />
                                </Grid>
                                <Grid item key="companyTitles" md={10}>
                                    <Typography component="p" className={classes.footerSiteTitle}>
                                        {company.name}
                                    </Typography>
                                    <Typography component="p" className={classes.footerSiteSubTitle}>
                                        {company.slogan}
                                    </Typography>
                                </Grid>
                            </Grid>                            
                        </Grid>
                        <Grid item key="companyFooter3" xs={12} sm={12} md={4}>
                            <Grid container>
                                <Grid item key="companyAddress" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                        <RoomIcon sx={{ verticalAlign: 'bottom' }} /> {company.address}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyEmail" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                       <EmailIcon sx={{ verticalAlign: 'bottom' }} /> {company.email}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyPhone" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                        <LocalPhoneIcon sx={{ verticalAlign: 'bottom' }} /> {company.phone}
                                    </Typography>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item key="companyFooter2" xs={12} sm={12} md={2}>
                            <Typography variant="body2" mt={2}>
                                <Link color="inherit" href={privacyLinks[0].link} target="_blank" title={privacyLinks[0].title} sx={{ textDecoration: 'none' }}>{privacyLinks[0].title}</Link>
                            </Typography>
                            <Typography variant="body2" className={classes.mobileAdminLink}>
                                <Link color="inherit" href="/admin" sx={{ textDecoration: 'none' }}>Area Protetta</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        <Typography variant="body2" align="center">
                            {'Copyright © '}
                            <Link color="inherit" component={NavLink} to="/" sx={{ textDecoration: 'none' }}>
                                {company.name}
                            </Link>
                            {' '}
                            {new Date().getFullYear()}
                        </Typography>
                        <Typography variant="body2" align="center" mt={1}>
                            <Link color="inherit" href="https://www.actainfo.it" target="_blank" sx={{ textDecoration: 'none' }}>
                                Design & Development by <img src={ActaLogo} className={classes.actalogo} width="20" alt="Logo Actainfo" /> Actainfo 
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}

export default Footer;