import React from 'react';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { NavLink } from 'react-router-dom';
import { DataGrid, itIT } from '@mui/x-data-grid';

import { useStyles } from './AdminMainStyles.js';

function DataTable({ latestReports }) {

    const classes = useStyles();

    const [reportList, setReportList] = useState(null);

    useEffect(() => {
        (async() => {
            const reportList = await latestReports();
            setReportList(reportList);
        })();
    }, [latestReports]);  

    let columns = [];
    
    columns = [
        { field: 'dateFormat', headerName: 'Data Segnalazione', flex: 0.2, },
        { field: 'categoryName', headerName: 'Categoria', flex: 0.5, },
        { 
            field: 'address', 
            headerName: 'Indirizzo', 
            flex: 1, 
            renderCell: (params) => (
                <Link to={`/admin/segnalazioni/${params.id}`} className={classes.tableLink} component={NavLink}>
                    {`${params.getValue(params.id, 'address')}`}
                </Link>
            )
        },
        {
            field: 'reporterFullName',
            headerName: 'Segnalatore',
            flex: 0.5,
            valueGetter: (params) =>
              `${params.getValue(params.id, 'reporter_last_name') || ''} ${
                params.getValue(params.id, 'reporter_first_name') || ''
            }`,
        },
        { field: 'reporter_email', headerName: 'Email', flex: 0.4, },
        { field: 'reporter_phone', headerName: 'Telefono', flex: 0.2, },
    ];
    

    if(!reportList || reportList.length === 0) return <p></p>;
    return (
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={reportList}
            columns={columns}
            autoHeight
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            pageSize={10}
            pagination="false"
          />
        </div>
    );
}


function AdminMain({ company, listReports, latestReports }) {

    const classes = useStyles();

    const [activeList, setActiveList] = useState(null);

    useEffect(() => {
        (async() => {
            const activeList = await listReports('active');
            setActiveList(activeList);
        })();
    }, [listReports]);  

    const [archivedList, setArchivedList] = useState(null);

    useEffect(() => {
        (async() => {
            const archivedList = await listReports('archived');
            setArchivedList(archivedList);
        })();
    }, [listReports]); 

    if(!activeList) return <Box sx={{ minHeight: '100vh' }} />;
    if(!archivedList) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <Container component="main" maxWidth="xl" className={classes.pageWrapper}>
            <Grid container>
                <Grid item xs={12} sm={12} md={2} lg={1}>
                    <img src={company.logo} width="100" className={classes.siteImg} alt={company.name} />      
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={11}>
                    <Typography className={classes.pageTitle}>
                        {company.name}
                    </Typography>
                    <Typography className={classes.pageSubTitle}>
                        {company.slogan}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper className={classes.paperWrapper}>
                        <Typography className={classes.boxTitle}>
                            Segnalazioni Ricevute
                        </Typography>
                        <Typography className={classes.boxCounter}>
                            {activeList.length}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper className={classes.paperWrapper}>
                        <Typography className={classes.boxTitle}>
                            Segnalazioni Risolte
                        </Typography>
                        <Typography className={classes.boxCounter}>
                            {archivedList.length}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper className={classes.paperWrapper}>
                        <Typography className={classes.boxTitle}>
                            Segnalazioni Totali
                        </Typography>
                        <Typography className={classes.boxCounter}>
                            {archivedList.length + activeList.length}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>  
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Link to='/admin/segnalazioni/stato/attive' className={classes.boxedLink} component={NavLink}><ReportProblemIcon /> Vai alle segnalazioni ricevute</Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Link to='/admin/segnalazioni/stato/risolte' className={classes.boxedLink} component={NavLink}><FolderOpenIcon /> Vai alle segnalazioni risolte</Link>
                    </Box>
                </Grid>
            </Grid> 
            <Box mt={5}>
                <Typography className={classes.tableTitle}>
                    <FormatListNumberedIcon /> Ultime segnalazioni ricevute
                </Typography>
                <DataTable latestReports={latestReports} />
            </Box>
        </Container>
    );

}

export default AdminMain;