import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { NavLink } from 'react-router-dom';

import { useStyles } from './ReportDetailsStyles.js';


function ReportDetails({ reportDetails }) {

    const classes = useStyles();

    const { reportID } = useParams();

    const [report, setReport] = useState(null);

    let actionResult = new URLSearchParams(window.location.search).get('action');

    useEffect(() => {
        if(actionResult) {
            actionResult = actionResult.replace(/(<([^>]+)>)/gi, "");
        } else {
            actionResult = '';
        }  
    });
    
    useEffect(() => {
        (async() => {
            const report = await reportDetails(reportID);
            setReport(report);
        })();
    }, [reportDetails, reportID]);

    const pageTitle = 'Dettaglio Segnalazione';

    if(!report || report.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <Container component="main" maxWidth="xl" className={classes.pageWrapper}>
            {(actionResult === 'archiviata') && <p><Alert severity="success" className={classes.actionResult}>Segnalazione risolta.</Alert></p>}
            {(actionResult === 'ripristinata') && <p><Alert severity="success" className={classes.actionResult}>Segnalazione ripristinata correttamente</Alert></p>}
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.reportTitle}>
                        {pageTitle}
                    </Typography>
                </Grid>
                {(report.state === 'active') &&
                    <Grid item xs={12} sm={12} md={6} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" to={'/admin/segnalazioni/modifica/' + report.id} className={classes.manageReport} component={NavLink}>Risolvi</Button>
                        <Button variant="contained" to={'/admin/segnalazioni/elimina/' + report.id} className={classes.deleteButton} component={NavLink}>Elimina</Button>
                    </Grid>
                }

                {(report.state === 'archived') &&
                    <Grid item xs={12} sm={12} md={6} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" to={'/admin/segnalazioni/modifica/' + report.id} className={classes.manageReport} component={NavLink}>Ripristina</Button>
                        <Button variant="contained" to={'/admin/segnalazioni/elimina/' + report.id} className={classes.deleteButton} component={NavLink}>Elimina</Button>
                    </Grid>
                }
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Data Segnalazione:</strong> {report.dateFormat}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Segnalatore:</strong> {report.reporter_last_name} {report.reporter_first_name}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Email:</strong> {report.reporter_email}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Telefono:</strong> {report.reporter_phone}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Categoria:</strong> {report.categoryName}</Typography>                        
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Typography className={classes.reportInfo}><strong>Indirizzo:</strong> {report.address}</Typography>                        
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.reportInfo}><strong>Messaggio segnalazione:</strong> <br/><br/> {report.description}</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} mt={5}>
                        <Typography className={classes.titillium}>
                            <strong>Immagine 1</strong>
                        </Typography>
                        {(report.report_image_1 !== null) ? <img src={report.report_image_1} alt="Immagine 1" title="Immagine 1" className={classes.reportImg} /> : <Typography className={classes.titillium}>Nessuna Immagine</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} mt={5}>
                        <Typography className={classes.titillium}>
                            <strong>Immagine 2</strong>
                        </Typography>
                        {(report.report_image_2 !== null) ? <img src={report.report_image_2} alt="Immagine 2" title="Immagine 2" className={classes.reportImg} /> : <Typography className={classes.titillium}>Nessuna Immagine</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} mt={5}>
                        <Typography className={classes.titillium}>
                            <strong>Immagine 3</strong>
                        </Typography>
                        {(report.report_image_3 !== null) ? <img src={report.report_image_3} alt="Immagine 3" title="Immagine 3" className={classes.reportImg} /> : <Typography className={classes.titillium}>Nessuna Immagine</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );

}

export default ReportDetails;