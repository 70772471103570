import React from 'react';
import { Outlet  } from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

function MainLayout({ company, isAuth, user, login, logout, privacyLinks }) {


    return(
        <>
        <Header company={company} isAuth={isAuth} user={user} login={login} logout={logout} />
        <Outlet />
        <Footer company={company} privacyLinks={privacyLinks}/>
        </>
    );
}

export default MainLayout;