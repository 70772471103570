import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import PersonIcon from '@mui/icons-material/Person';

import { useStyles } from './HeaderStyles.js';


function Header({ company, isAuth, user, login, logout }) { 

    const classes = useStyles();

    return (
        <>            
        <AppBar position="sticky" className={classes.appBar}>
            <Container maxWidth="xl" className={classes.headerWrapper}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <img src={company.logo} width="60" className={classes.mobileSiteImg} alt={company.name} />
                        <Typography sx={{ display: 'block' }} ><Link to='/' component={NavLink} underline="none" className={classes.siteNameTop}>{company.name}</Link></Typography>
                    </IconButton>
                    <Typography 
                        variant="h6" 
                        color="inherit" 
                        noWrap
                        sx={{ flexGrow: 1 }}
                        className={classes.appBarWrapper}
                    >
                   {/* {headerLinks.map((header) => (
                        
                        <Link 
                            component={NavLink}
                            to={header.link}
                            underline="none"
                            className={classes.link}
                            key={`header_${header.title}`}
                        >
                            {header.title}
                        </Link>
                    ))}*/}
                    </Typography>
                    {!isAuth
                    ? 
                        <Button to="/login" component={NavLink} color="inherit" variant="outlined" className={classes.loginButton}>Area Protetta</Button>
                    : 
                        <>
                        <Typography className={classes.loggedMessage}><PersonIcon sx={{ verticalAlign: 'sub' }} /> {user}</Typography>
                        <Button href='/admin' color="inherit" variant="contained" className={classes.dashboardButton}>Area Protetta</Button>
                        <Button onClick={logout} color="inherit" variant="outlined" className={classes.loginButton}>Logout</Button>
                        </>
                }             
                </Toolbar>
                <Typography className={classes.companySlogan}>{company.slogan}</Typography>
            </Container>
        </AppBar>
        </>
    );
}

export default Header;