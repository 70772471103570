import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { useStyles } from './LoginFormStyles.js';

function LoginForm({ login, company }) {

    const classes = useStyles();

    const initialFormData = Object.freeze({
        username: '',
        password: '',
    });

    const [formData, updateFormData] = useState(initialFormData); 

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const [loginResponse, setLoginResponse] = useState();

    const handleSubmit = async(e) => {
        e.preventDefault();          

        try {
            await login(formData);
            window.location.replace('/admin');
        } catch(err) {
            setLoginResponse('Error');
        }
    }

    const pageInfo =[
        {
            title: 'Area Protetta',
            subtitle: 'Effettua il login',
        },
    ];

    return (
        <>
        <Container component="main" maxWidth="xl" sx={{ paddingTop: '50px', paddingBottom: '50px' }}> 
            <Box className={classes.pageTitleWrapper} >
                <Container maxWidth="xl">
                    <Typography variant="h1" className={classes.pageTitle}>
                        {pageInfo[0].title}
                    </Typography>
                    <Typography variant="body2" className={classes.pageSubTitle}>
                        {pageInfo[0].subtitle}
                    </Typography>
                </Container>
            </Box>       
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} className={classes.loginWrapper}>
                        <Typography className={classes.loginTitle}>
                            Login
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Nome utente"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={handleChange}
                                className={classes.inputField}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                className={classes.inputField}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                Login
                            </Button>
                        </form>
                        {loginResponse === 'Error' && <Typography className={classes.loginErrors}>Nome utente o password errati</Typography>}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ padding: '50px' }}>
                        <img src={company.logo} width="80" alt={company.name} />
                        <Typography variant="h2" className={classes.companyInfoTitle}>
                            {company.name}
                        </Typography>
                        <Typography variant="h4" component="h3" mb={5} className={classes.companyInfoSubTitle}>
                            {company.slogan}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>                        
        </Container>
        </>
    );
}

export default LoginForm;