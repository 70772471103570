import React from 'react';
import Container from '@mui/material/Container';
import ReportForm from '../../components/ReportForm/ReportForm';

import { useStyles } from './HomePageStyles.js';


function HomePage({ company, listCategories, submitReport, privacyLinks }) {

    const classes = useStyles();

    return (
    <>     
        <Container maxWidth="xl" component="main" className={classes.formWrapper}>
            <ReportForm listCategories={listCategories} submitReport={submitReport} company={company} privacyLinks={privacyLinks} />
        </Container>          
    </>
    );
}

export default HomePage;