import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { useStyles } from './EditReportStyles.js';

function EditReport({ reportDetails, editReport }) {

    const classes = useStyles();

    const navigate = useNavigate();

    const { reportID } = useParams();

    const [report, setReport] = useState(null);

    const [postData, updatePostData] = useState({
        state: '',
    });
    
    useEffect(() => {
        (async() => {
            const report = await reportDetails(reportID);
            setReport(report);
            if(report.state === 'active') {
                updatePostData({
                    ...postData,
                    'state': 'archived',
                })
            } else {
                updatePostData({
                    ...postData,
                    'state': 'active',
                })
            }
            
        })();
    }, [reportDetails, reportID]);    

    let pageTitle = '';
    let formText = '';
    let formButton = '';

    if(!report || report.length === 0) return <Box sx={{ minHeight: '100vh' }} />;

    if(report.state === 'active'){
        pageTitle = 'Risolvi Segnalazione';
        formText = 'La segnalazione è stata risolta?'; 
        formButton = 'Risolvi Segnalazione';
    } else if(report.state === 'archived'){
        pageTitle = 'Ripristina Segnalazione';
        formText = 'Procedere con il ripristino?'; 
        formButton = 'Conferma Ripristino';        
    } else {
        window.location.replace('/admin');
    }    

    const handleSubmit = async(e) => {
        e.preventDefault();

        await editReport(reportID, postData);

        if(postData.state === 'archived') {
            navigate({
                pathname: '/admin/segnalazioni/' + reportID + '/?action=archiviata',
            });
        } else {
            navigate({
                pathname: '/admin/segnalazioni/' + reportID + '/?action=ripristinata',
            });
        }        
        
        //window.location.reload();       
    }

    return (
       <Container component="main" maxWidth="xl" className={classes.pageWrapper}>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.reportTitle}>
                        {pageTitle}
                    </Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Data Segnalazione:</strong> {report.dateFormat}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Segnalatore:</strong> {report.reporter_last_name} {report.reporter_first_name}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Email:</strong> {report.reporter_email}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Telefono:</strong> {report.reporter_phone}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Categoria:</strong> {report.categoryName}</Typography>                        
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Typography className={classes.reportInfo}><strong>Indirizzo:</strong> {report.address}</Typography>                        
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.reportInfo}><strong>Messaggio segnalazione:</strong> <br/><br/> {report.description}</Typography>
                </Grid>
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
                <Typography mt={5} mb={2} className={classes.titllium}>{formText}</Typography>
                <Button 
                    variant="contained"
                    className={classes.manageReport}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {formButton}
                </Button>  
            </Box>
        </Container>
    )

}

export default EditReport;