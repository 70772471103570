import React from "react";

const ReportMap = ({coords}) => { 
    let latitude = coords.lat;
    let longitude = coords.lng;
    //const MY_API = process.env.REACT_APP_GOOGLE_KEY;
    const MY_API = 'AIzaSyAQ4jAmN95GznhDL9ih25vcWHVk4VoTwzo';
    let querystring = 'q='+latitude+','+longitude;
    let _url = `https://www.google.com/maps/embed/v1/place?key=${MY_API}&zoom=18&`+querystring;
    return (
        <div className="col-lg-5 col-md-12">
          <iframe frameBorder="0" width="100%" height="340px" src={_url} title="Google Map" >
          </iframe>
        </div>
    )
};

export default ReportMap;