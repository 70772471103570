import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
        padding: '50px 30px',
        backgroundColor: '#f9f9f9',
        marginTop: '50px',
    },
    loginTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        color: '#1e375f',
        fontWeight: '600',
    },
    submit: {
        padding: '20px',
    },
    loginErrors: {
        color: 'red', 
        textAlign: 'center',
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        marginTop: '10px',
    },
    inputField: {
        backgroundColor: '#fff',
    },
    companyInfoTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
        },
    },
    companyInfoSubTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        color: '#6f97ff',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    pageTitleWrapper: {        
        marginTop: '50px',
        [theme.breakpoints.down('md')]: {
           marginTop: '20px',
        },
    },
    pageTitle: {
        fontSize: '3.5rem',
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
        [theme.breakpoints.down('md')]: {
           paddingLeft: '0',
        },
    },
    pageSubTitle: {
        color: '#1e375f',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
           paddingLeft: '0',
        },
    },
}));

export { useStyles };