import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { useStyles } from './DeleteReportStyles.js';

function DeleteReport({ reportDetails, deleteReport }) {

    const classes = useStyles();

    const navigate = useNavigate();

    const { reportID } = useParams();

    const [report, setReport] = useState(null);
    
    useEffect(() => {
        (async() => {
            const report = await reportDetails(reportID);
            setReport(report);            
        })();
    }, [reportDetails, reportID]);


    const handleSubmit = async(e) => {
        e.preventDefault();

        await deleteReport(reportID);
        
        navigate({
            pathname: '/admin/segnalazioni/stato/attive/?action=eliminata',
        });            
        
        //window.location.reload();       
    }

    if(!report || report.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return (
       <Container component="main" maxWidth="xl" className={classes.pageWrapper}>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className={classes.reportTitle}>
                        Elimina Segnalazione
                    </Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Data Segnalazione:</strong> {report.dateFormat}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Segnalatore:</strong> {report.reporter_last_name} {report.reporter_first_name}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Email:</strong> {report.reporter_email}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Telefono:</strong> {report.reporter_phone}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography className={classes.reportInfo}><strong>Categoria:</strong> {report.categoryName}</Typography>                        
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Typography className={classes.reportInfo}><strong>Indirizzo:</strong> {report.address}</Typography>                        
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.reportInfo}><strong>Messaggio segnalazione:</strong> <br/><br/> {report.description}</Typography>
                </Grid>
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
                <Typography mt={5} mb={2} className={classes.titllium}>Procedere con l'eliminazione?</Typography>
                <Button 
                    variant="contained"
                    className={classes.deleteButton}
                    type="submit"
                    onClick={handleSubmit}
                >
                   Conferma Eliminazione
                </Button>  
            </Box>
        </Container>
    )

}

export default DeleteReport;