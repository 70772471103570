import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appointmentFormGrid: {
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    formMainWrapper: {
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
        },
    },
    formWrapper: {
        padding: '40px',
        backgroundColor: '#f9f9f9',
        [theme.breakpoints.up('md')]: {
            minHeight: '500px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10px',
        },
    },
    formTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        color: '#1e375f',
        fontWeight: '600',
    },
    companyInfoTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
        },
    },
    companyInfoSubTitle: {
        fontFamily: "'Titillium Web', sans-serif",
        color: '#6f97ff',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    submit: {
        padding: '20px',
    },
    formResponse: {
        fontFamily: "'Titillium Web', sans-serif",
    },
    inputField: {
        backgroundColor: '#fff',
    },
    error: {
        color: '#f44336',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginLeft: '10px',
        marginBottom: '10px',
    },
    formText: {
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
    },
    helperText: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '.9rem',
    },
    checkboxLabel: {
        '& span': {
            fontFamily: "'Titillium Web', sans-serif !important",
        },
    },
}));

export { useStyles };