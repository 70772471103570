import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        paddingTop: '80px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    pageTitle: {
        color: '#1e375f',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginLeft: '5px',
    },
    pageSubTitle: {
        color: '#5a5a5a',
        fontFamily: "'Titillium Web', sans-serif",
        marginBottom: '20px',
        marginLeft: '5px',
    },
    reportInfo: {
        fontSize: '1rem',
        fontFamily: "'Titillium Web', sans-serif",
        marginTop: '10px',
        whiteSpace: 'pre-line',
        padding: '10px',
        border: '1px solid #212121',
        wordBreak: 'break-word',
    },
    reportImg: {
        maxWidth: '100%',
    },
    titillium: {
        fontSize: '1rem',
        fontFamily: "'Titillium Web', sans-serif",
    },
    manageReport: {
        width: '250px',
        backgroundColor: '#00b74a',
        color: '#ffffff',
        border: '1px solid #00b74a',
        padding: '5px 15px',
        borderRadius: '4px',
        margin: '2px',
        '&:hover': {
            backgroundColor: '#00913b',
            color: '#fff',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    reportTitle: {
        color: '#1e375f',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '10px',
    },
    deleteButton: {
        width: '250px',
        border: '1px solid #b71c1c',
        color: '#ffffff',
        margin: '2px',
        backgroundColor: '#b71c1c',
        '&:hover': {
            backgroundColor: '#d50000',
            border: '1px solid #b71c1c',
            color: '#fff',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export { useStyles };