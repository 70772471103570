import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';

import { useStyles } from './ReportsListStyles.js';

function DataTable({ listReports, state }) {

    const classes = useStyles();

    const [reportList, setReportList] = useState(null);

    useEffect(() => {
        (async() => {
            const reportList = await listReports(state);
            setReportList(reportList);
        })();
    }, [listReports]);  

    let columns = [];
    
    columns = [
        { field: 'dateFormat', headerName: 'Data Segnalazione', flex: 0.2, },
        { field: 'categoryName', headerName: 'Categoria', flex: 0.5, },
        { 
            field: 'address', 
            headerName: 'Indirizzo', 
            flex: 1, 
            renderCell: (params) => (
                <Link to={`/admin/segnalazioni/${params.id}`} className={classes.tableLink} component={NavLink}>
                    {`${params.getValue(params.id, 'address')}`}
                </Link>
            )
        },
        {
            field: 'reporterFullName',
            headerName: 'Segnalatore',
            flex: 0.5,
            valueGetter: (params) =>
              `${params.getValue(params.id, 'reporter_last_name') || ''} ${
                params.getValue(params.id, 'reporter_first_name') || ''
            }`,
        },
        { field: 'reporter_email', headerName: 'Email', flex: 0.4, },
        { field: 'reporter_phone', headerName: 'Telefono', flex: 0.2, },
    ];
    

    if(!reportList || reportList.length === 0) return <p></p>;
    return (
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={reportList}
            columns={columns}
            autoHeight
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        </div>
    );
}

function ReportsList({ listReports }) {

    const classes = useStyles();

    const { state } = useParams();

    let actionResult = new URLSearchParams(window.location.search).get('action');

    useEffect(() => {
        if(actionResult) {
            actionResult = actionResult.replace(/(<([^>]+)>)/gi, "");
        } else {
            actionResult = '';
        }  
    });

    let pageTitle = '';
    let subTitle = ''

    if(state === 'attive'){
        pageTitle = 'Segnalazioni ricevute';
        subTitle = 'Le segnalazioni inviate dai cittadini'
    } else if(state === 'risolte'){
        pageTitle = 'Segnalazioni risolte';
        subTitle = 'Le segnalazioni risolte'
    } else {
        window.location.replace('/admin');
    }

    return(
        <Container component="main" maxWidth="xl" className={classes.pageWrapper}>
            {(actionResult === 'eliminata') && <p><Alert severity="success" className={classes.actionResult}>Segnalazione eliminata correttamente.</Alert></p>}
            <Typography className={classes.pageTitle}>
                {pageTitle}
            </Typography>
            <Typography className={classes.pageSubTitle}>
                {subTitle}
            </Typography>
            <DataTable listReports={listReports} state={state} />
        </Container>
    );

}

export default ReportsList;